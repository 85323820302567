/* stylelint-disable selector-id-pattern */
html,
body,
#__next {
  @apply h-full;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 5rem;
}

body {
  @apply bg-muted text-foreground dark:bg-background;
}

a {
  @apply text-primary;
}

.break-anywhere {
  overflow-wrap: anywhere;
  word-break: break-word;
}
